/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-plus: #ffffff;
  --ion-color-plus-rgb: 255, 255, 255;
  --ion-color-plus-contrast: #f04141;
  --ion-color-plus-contrast-rgb: 245, 61, 61;
  --ion-color-plus-shade: #d7d8da;
  --ion-color-plus-tint: #f5f6f9;

  --ion-color-gold: #ffffff;
  --ion-color-gold-rgb: 255, 255, 255;
  --ion-color-gold-contrast: #ffce00;
  --ion-color-gold-contrast-rgb: 255, 206, 0;
  --ion-color-gold-shade: #d7d8da;
  --ion-color-gold-tint: #f5f6f9;

  --ion-color-navy: #000080;
	--ion-color-navy-rgb: 0,0,128;
	--ion-color-navy-contrast: #ffffff;
	--ion-color-navy-contrast-rgb: 255,255,255;
	--ion-color-navy-shade: #000071;
	--ion-color-navy-tint: #1a1a8d;

  //khaki
	--ion-color-khaki: #f6e9d2;
	--ion-color-khaki-rgb: 246,233,210;
	--ion-color-khaki-contrast: #000000;
	--ion-color-khaki-contrast-rgb: 0,0,0;
	--ion-color-khaki-shade: #d8cdb9;
	--ion-color-khaki-tint: #f7ebd7;

  //blumine
  --ion-color-blumine: #1e517b;
	--ion-color-blumine-rgb: 30,81,123;
	--ion-color-blumine-contrast: #ffffff;
	--ion-color-blumine-contrast-rgb: 255,255,255;
	--ion-color-blumine-shade: #1a476c;
	--ion-color-blumine-tint: #356288;

  //khaki-dark
  --ion-color-khaki-dark: #c2b091;
	--ion-color-khaki-dark-rgb: 194,176,145;
	--ion-color-khaki-dark-contrast: #000000;
	--ion-color-khaki-dark-contrast-rgb: 0,0,0;
	--ion-color-khaki-dark-shade: #ab9b80;
	--ion-color-khaki-dark-tint: #c8b89c;

  //creamlight
  --ion-color-creamlight: #fdf6ed;
	--ion-color-creamlight-rgb: 253,246,237;
	--ion-color-creamlight-contrast: #000000;
	--ion-color-creamlight-contrast-rgb: 0,0,0;
	--ion-color-creamlight-shade: #dfd8d1;
	--ion-color-creamlight-tint: #fdf7ef;

  //cream
  --ion-color-cream: #faf2e4;
  --ion-color-cream-rgb: 250,242,228;
  --ion-color-cream-contrast: #000000;
  --ion-color-cream-contrast-rgb: 0,0,0;
  --ion-color-cream-shade: #dcd5c9;
  --ion-color-cream-tint: #fbf3e7;
}

.ion-color-plus {
  --ion-color-base: var(--ion-color-plus);
  --ion-color-base-rgb: var(--ion-color-plus-rgb);
  --ion-color-contrast: var(--ion-color-plus-contrast);
  --ion-color-contrast-rgb: var(--ion-color-plus-contrast-rgb);
  --ion-color-shade: var(--ion-color-plus-shade);
  --ion-color-tint: var(--ion-color-plus-tint);
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-navy {
  --ion-color-base: var(--ion-color-navy);
  --ion-color-base-rgb: var(--ion-color-navy-rgb);
  --ion-color-contrast: var(--ion-color-navy-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navy-contrast-rgb);
  --ion-color-shade: var(--ion-color-navy-shade);
  --ion-color-tint: var(--ion-color-navy-tint);
}

.ion-color-khaki {
	--ion-color-base: var(--ion-color-khaki);
	--ion-color-base-rgb: var(--ion-color-khaki-rgb);
	--ion-color-contrast: var(--ion-color-khaki-contrast);
	--ion-color-contrast-rgb: var(--ion-color-khaki-contrast-rgb);
	--ion-color-shade: var(--ion-color-khaki-shade);
	--ion-color-tint: var(--ion-color-khaki-tint);
}

.ion-color-khaki-dark {
	--ion-color-base: var(--ion-color-khaki-dark);
	--ion-color-base-rgb: var(--ion-color-khaki-dark-rgb);
	--ion-color-contrast: var(--ion-color-khaki-dark-contrast);
	--ion-color-contrast-rgb: var(--ion-color-khaki-dark-contrast-rgb);
	--ion-color-shade: var(--ion-color-khaki-dark-shade);
	--ion-color-tint: var(--ion-color-khaki-dark-tint);
}

.ion-color-cream {
	--ion-color-base: var(--ion-color-cream);
	--ion-color-base-rgb: var(--ion-color-cream-rgb);
	--ion-color-contrast: var(--ion-color-cream-contrast);
	--ion-color-contrast-rgb: var(--ion-color-cream-contrast-rgb);
	--ion-color-shade: var(--ion-color-cream-shade);
	--ion-color-tint: var(--ion-color-cream-tint);
}

.ion-color-creamlight {
	--ion-color-base: var(--ion-color-creamlight);
	--ion-color-base-rgb: var(--ion-color-creamlight-rgb);
	--ion-color-contrast: var(--ion-color-creamlight-contrast);
	--ion-color-contrast-rgb: var(--ion-color-creamlight-contrast-rgb);
	--ion-color-shade: var(--ion-color-creamlight-shade);
	--ion-color-tint: var(--ion-color-creamlight-tint);
}

.ion-color-blumine {
	--ion-color-base: var(--ion-color-blumine);
	--ion-color-base-rgb: var(--ion-color-blumine-rgb);
	--ion-color-contrast: var(--ion-color-blumine-contrast);
	--ion-color-contrast-rgb: var(--ion-color-blumine-contrast-rgb);
	--ion-color-shade: var(--ion-color-blumine-shade);
	--ion-color-tint: var(--ion-color-blumine-tint);
}
/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
:root,
:root[mode="ios"],
:root[mode="md"] {
  --ion-font-family: "CustomFont" !important;
  font-family: "CustomFont" !important;
}
@font-face {
  font-family: "CustomFont";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Muli.ttf");
}

@font-face {
  font-family: "hipster";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/hipster.ttf");
}

.swiper-pagination {
  // bottom: 0 !important;
}

.swiper-pagination-bullet-active {
  background: var(--ion-color-blumine) !important; //match this with accent colors
}

.swiper-pagination-bullet {
  background: var(--ion-color-medium);
}

ion-modal {
  --background: #383a3ec2 !important;

}
.swiper-scrollbar {
  top: 3px !important;
}
.swiper-scrollbar-drag {
  background: var(--ion-color-warning-contrast);
  height: 90%;

}
.searchbar-input.sc-ion-searchbar-md {
  box-shadow: none !important;
  -webkit--webkit-box-shadow: none !important;
}
.searchbar-search-icon.sc-ion-searchbar-md {
  color: var(--ion-color-primary-tint);
}
ion-content {
  --background: var(--ion-color-khaki);
}
.custom-bg-class .modal-wrapper .ion-page ion-content {
  --background: rgba(0, 0, 0, 0.1);
  margin-block-start: 10%;
  p{
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }
}

.custom-bg-class .modal-wrapper{
  height: 90%;
  width: 90%;
}

app-home .header-md:after {
  background-image: none !important;
  content: "";
}
ion-card ion-item ion-toggle {
  padding-right: 5px;
}

ion-item.entry{
  --highlight-background: var(--ion-color-blumine-shade) !important;
  --background: var(--ion-color-creamlight) !important;
}

ion-button{
  --background: var(--ion-color-blumine);
  color: var(--ion-color-creamlight);
}

ion-header ion-toolbar{
  --background: var(--ion-color-creamlight);
}